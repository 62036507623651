import React from 'react';
import { TopNav } from './TopNav';
import { Footer } from './Footer';

export const Layout = ({ children }) => {
  return (
    <div className='flex flex-col h-screen justify-between'>
      <div className='nav-and-main-container'>
        <TopNav />
        {children}
      </div>
      <Footer />
    </div>
  );
};
