import React, { useState } from 'react';
import { useRemoveItemFromCart, useStore, useUpdateItem } from './StoreContext';

export const LineItem = ({ item }) => {
  const [quantity, setQuantity] = useState(Number(item.quantity));
  const removeItemFromCart = useRemoveItemFromCart();
  const updateItem = useUpdateItem();
  const { updating } = useStore();

  const handleUpdate = (e) => {
    const { value } = e.target;
    setQuantity(value);
    updateItem(item.id, value);
  };

  return (
    <li className="flex flex-col sm:flex-row items-center justify-between w-full mb-4">
      <div className="flex flex-col sm:flex-row items-center">
        <div className="flex items-center">
          <button
            disabled={updating}
            className="rounded-md p-2 bg-blue-500 text-white h-10 m-2 block"
            onClick={() => removeItemFromCart(item.id)}
          >
            X
          </button>
          <img
            className="w-32 mb-4 sm:mb-0 sm:mr-8"
            src={item.variant.image.src}
          />
        </div>
        <p>{item.title}: </p>
        <input
          className="border border-blue-500 m-2 rounded-md p-0 w-10"
          type="number"
          min="1"
          value={quantity}
          onChange={handleUpdate}
          disabled={updating}
        />
      </div>
      <p>{(item.variant.price * item.quantity).toFixed(2)}</p>
    </li>
  );
};
