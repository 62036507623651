import React from 'react';
import { Layout } from '../components/Layout';
import { useCartItems, useRemoveItemFromCart, useCartTotals, useClearCart, useCheckout, useUpdateItem, useStore } from '../components/StoreContext';
import { LineItem } from '../components/LineItem';

export default function ShoppingCart() {
  const items = useCartItems();
  const { total } = useCartTotals()
  const clearCart = useClearCart()
  const checkout = useCheckout()
  const { updating } = useStore()
  
  const emptyCart = items.length === 0;

  const lineItems = emptyCart ? (<p>Hmm... looks like you haven't added any items to your cart</p>) : items.map(item => <LineItem key={item.id} item={item} />)

  return(
    <Layout>
      <section className="max-w-screen-xl m-auto mb-12 p-6">
        <h2 className="text-2xl text-center mb-8">Shopping Cart</h2>
        <div className="p-6">
          <ul className="flex flex-wrap flex-col justify-center max-w-screen-lg m-auto">
            {lineItems}
          </ul>
          {!emptyCart && <div className="flex justify-between items-end">
            <button disabled={updating} onClick={clearCart} className="rounded-md h-10 p-2 bg-blue-500 text-white block">Clear Cart</button>
            <div className="flex flex-col justify-right">
              <p className="text-blue-500 text-center text-xl mb-4">total - {total}</p>
              <button disabled={updating} onClick={checkout} className="rounded-md p-2 bg-blue-500 text-white block">Checkout</button>
            </div>
          </div>}
        </div>
      </section>
    </Layout>
  )
}